import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';

import { URL_KEYS_ROUTES_EN } from '../../constants/routes';

import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';

import iconTick from '../../assets/images/svg/tick.svg';
import imgBlockR from '../../assets/images/about-us/Photo 1.png';
import iconTrinity from '../../assets/images/about-us/photo 2.png';

import imgBenefit1 from '../../assets/images/about-us/A rewarding job .svg';
import imgBenefit2 from '../../assets/images/about-us/Comfortable accommodation.svg';
import imgBenefit3 from '../../assets/images/about-us/A network of friends .svg';
import imgBenefit4 from '../../assets/images/about-us/Spanish Bureaucracy .svg';
import imgDots from '../../assets/images/home-page/Dots.png';

import imgPhoto from '../../assets/images/about-us/Photo 3.png';

import '../../assets/styles/pages/about-us.scss';

const AboutUs = () => {
  return (
    <Layout
      title={'About Iberia Language Academy | Our Barcelona Story'}
      description={
        'Find out how Iberia Language Academy became the best school in Barcelona for your Spanish language journey!'
      }
    >
      <div className="about-us-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">The Iberia Language Academy Story</h1>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-md-6">
                <div className="block_l">
                  <h2 className="c-title-34 block__title">Welcome to Iberia Language Academy!</h2>
                  <p className="c-text-18 block__subtitle">
                    At Iberia Language Academy (ILA), we are passionate about helping individuals
                    embark on a journey to learn Spanish in the vibrant city of Barcelona. Our roots
                    can be traced back to TEFL Iberia, an English teacher training academy founded
                    by Richard Davie in 2012. As we interacted with numerous students, primarily
                    from English-speaking countries, who were keen on relocating to Barcelona for
                    work and life, we realised the growing demand for Spanish language education.
                    This realisation inspired us to expand our offerings and establish ILA.
                    <br />
                    <br />
                    As a close-knit community of dedicated teachers and educators, we are committed
                    to providing an exceptional educational experience and outstanding customer
                    service. Our initial focus on teacher training seamlessly evolved into
                    developing our own Spanish courses. This evolution enables us to extend our
                    expertise and deliver the same level of excellence to individuals seeking to
                    embrace the language and culture of Barcelona.
                  </p>

                  <h4 className="c-title-18">Why choose ILA for your language journey?</h4>
                  <p className="c-text-16 block__description">
                    Our entire management team has personally experienced the rewarding process of
                    learning Spanish from scratch. We understand the challenges and triumphs that
                    come with language acquisition, and we apply this invaluable insight to create
                    an enjoyable, high-quality learning experience for our students. We firmly
                    believe that language learning should be engaging, practical, and tailored to
                    individual needs.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgBlockR} alt="Tefl Iberia" className="block_r__img" />
                  </div>

                  <div className="block__logo block_r__trinity">
                    <img src={iconTrinity} alt="Trinity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section">
          <div className="wrapper container">
            <div className="block_l">
              <h3 className="c-title-34">What We Do</h3>
              <p className="c-text-18">
                We want to help you along the same path our young team took and provide everything
                you need to learn Spanish:
              </p>
            </div>
            <div className="block_r">
              <div className="benefit">
                <img src={imgBenefit1} alt="First-Class Teachers" />
                <div className="benefit__text">
                  <h4 className="c-title-18">First-Class Teachers</h4>
                  <p className="c-text-14">
                    All of our teachers are qualified, experienced, talented, and above all, very
                    friendly!
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgBenefit2} alt=" Help with Spanish Bureaucracy" />
                <div className="benefit__text">
                  <h4 className="c-title-18">Help with Spanish Bureaucracy</h4>
                  <p className="c-text-14">
                    Visas, forms, NIE, social security number, empadronamiento. We can take the pain
                    out of these processes!
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgBenefit3} alt=" Focus on Speaking" />
                <div className="benefit__text">
                  <h4 className="c-title-18">Focus on Speaking</h4>
                  <p className="c-text-14">
                    This is our top priority for you! To get you speaking Spanish as confidently as
                    possible.
                  </p>
                </div>
              </div>

              <div className="benefit">
                <img src={imgBenefit4} alt="Fun Cultural Activities" />
                <div className="benefit__text">
                  <h4 className="c-title-18">Fun Cultural Activities</h4>
                  <p className="c-text-14">
                    Spanish is best learned when you get out there and use it in the wild. And have
                    some fun along the way!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="video container">
            <div className="row">
              <div className="col-md-5 col-12">
                <div className="block_l">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/n80CtVC9PzU"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-md-7 col-12">
                <div className="block_r">
                  <p className="block_r__par c-text-16">
                    <FormattedMessage
                      id="about.sec3.text1"
                      values={{
                        accentedLink: (chunk) => (
                          <a
                            className="capitalized-link"
                            target="_blank"
                            href="https://www.teflcoursereview.com/school/tefl-iberia/"
                          >
                            {chunk}
                          </a>
                        ),
                      }}
                    />
                    <br />
                    <br />
                    <FormattedMessage id="about.sec3.text2" />
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </section>

        <section className="fourth-section c-section-py ">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">How We Do It</h2>
                  <p className="block_l__description c-text-18">
                    We’ve taught Spanish to hundreds of students and they’ve all got great things to
                    say about the school. They’ll definitely mention:
                  </p>
                  <div className="c-benefit-list">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        The personalised attention from the coordination staff and teachers.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Small groups (8 maximum, not 20).</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Lots of speaking practice in class.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Getting to meet people from all over the world.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Feeling at home in the school.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Additional support inside and outside the classroom
                      </p>
                    </div>
                  </div>

                  {/* <p className="block_l__terms">
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Languages+4+Life/@41.394561,2.1654172,3a,75y,126.81h,90t/data=!3m7!1e1!3m5!1sqkMPi3upO8QAAAQIt2LQTQ!2e0!3e2!7i5660!8i2830!4m5!3m4!1s0x0:0x7bb78801f01bb2b6!8m2!3d41.3947217!4d2.1654974?hl=es"
                      className="red-link"
                    >
                      Check the school out for yourself with our virtual tour!
                    </a>
                  </p> */}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <img src={imgPhoto} alt="imgPhoto" className="block_r__img" />
                  <img src={imgDots} alt="imgDots" className="dots" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fifth-section">
          <div className="container">
            <h2 className="c-title-38">
              Ready to Start Learning <span className="colored no-link">Spanish in Barcelona?</span>
            </h2>
            <FormattedMessage id={URL_KEYS_ROUTES_EN.APPLY}>
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  Let’s go
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutUs;
